import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const PreLoading = () => {
    return (
        <Box position="relative" display="flex" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
            <CircularProgress size={100} thickness={2}/>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
            </Box>
        </Box>
    );
};

export default PreLoading;
